<aside
  class="sidebar-menu fixed inset-y-0 left-0 z-50 flex h-screen w-4/6 min-w-64 max-w-xs flex-col transition-all duration-500 ease-in-out lg:w-64"
  [ngClass]="{
    'sidebar-menu--expanded': !isCollapsed()
  }"
>
  <div
    class="flex w-full items-center justify-between bg-brand-primary-pure !px-5 !py-3 lg:h-36 lg:!border-r lg:border-solid lg:border-brand-primary-lighter lg:bg-white"
  >
    <picture>
      <source
        srcset="assets/images/wide-logo-liv.png"
        type="image/png"
        media="(min-width: 1024px)"
      />
      <img
        src="assets/images/logo-liv-white.webp"
        alt="Logo do LIV"
        class="block h-10 w-auto object-cover lg:m-auto lg:h-[4.5rem]"
      />
    </picture>
    <button class="inline-flex text-white lg:hidden" (click)="toggleCollapse()">
      <ng-icon name="heroXMark" size="32" />
    </button>
  </div>

  <ul class="nav-list">
    <ng-container *ngFor="let item of menu">
      <li
        class="nav-list__item"
        *ngIf="item.enabled"
        (click)="toggleCollapse()"
      >
        <ng-container
          *ngIf="item.link?.target === '_blank'; else insideApplication"
        >
          <a
            class="nav-list__item__name"
            [href]="item.link.url"
            [target]="item.link.target"
            [attr.data-tracking-id]="'menu-' + item.name"
            rel="noopener noreferrer"
          >
            <svg-icon
              svgClass="nav-list__item__icon"
              [src]="'assets/icons/menu/' + item.icon"
            ></svg-icon>
            <span>{{ item.name }}</span>
          </a>
        </ng-container>

        <ng-template #insideApplication>
          <a
            class="nav-list__item__name"
            [routerLink]="[item.link.url]"
            routerLinkActive="active"
            [attr.data-tracking-id]="'menu-' + item.name"
          >
            <svg-icon [src]="'assets/icons/menu/' + item.icon"></svg-icon>
            <span>{{ item.name }}</span>
            @if (item.isNew) {
              <span
                class="!ml-2 flex h-5 w-fit items-center justify-center !rounded-lg !border border-feedback-success-medium bg-feedback-success-medium px-2 py-1 text-xs/loose font-medium text-neutral-hight-pure"
                >Novo</span
              >
            }
          </a>
        </ng-template>
      </li>
    </ng-container>

    <li class="nav-list__item" *ngIf="isAdminUser" (click)="toggleCollapse()">
      <button
        class="group h-full w-full border-0 bg-transparent p-0 outline-0 disabled:cursor-default disabled:opacity-60"
        type="button"
        (click)="onRequestMaterials()"
        [attr.data-tracking-id]="'PortalOps'"
        [disabled]="loadingMaterials"
      >
        <a class="nav-list__item__name group-disabled:cursor-wait">
          <commons-loader
            *ngIf="loadingMaterials; else loadedMaterials"
            [attr.data-tracking-id]="'menu-portalops'"
          ></commons-loader>
          <ng-template #loadedMaterials>
            <svg-icon src="assets/icons/menu/novo-pedido.svg"></svg-icon>
          </ng-template>
          <span>Pedidos</span>
        </a>
      </button>
    </li>
  </ul>
</aside>
