<header
  class="flex items-center justify-between border-b-[1px] border-b-neutral-hight-medium !pb-4 !pl-6 !pr-6 !pt-6"
  #modalHeader
>
  <h2 class="!mr-2 text-xl/5 font-medium text-neutral-low-dark">
    Notificações
  </h2>
  <button
    type="button"
    class="flex items-center justify-center rounded-[50%] !p-2 text-[0px] transition-all duration-300 ease-in-out hover:scale-105 hover:transform hover:bg-brand-primary-lightest"
    (click)="onClose()"
  >
    <svg-icon
      src="assets/icons/close.svg"
      class="fill-brand-primary-pure"
      [svgStyle]="{
        'width.px': 16,
        'height.px': 16
      }"
    ></svg-icon>
  </button>
</header>
<div class="overflow-y-auto !px-8 !pb-6 !pt-0">
  @if (notifications?.length > 0) {
    @for (notification of notifications; track notification.id) {
      <article
        class="!px-4 !pb-0 !pt-4 [&:not(:last-child)]:border-b-[1px] [&:not(:last-child)]:border-neutral-hight-medium"
      >
        <header class="!mb-3 flex justify-between align-baseline">
          <strong
            class="line-clamp-2 block max-w-[60%] overflow-hidden text-sm/6 font-semibold text-neutral-low-dark md:text-base/6"
          >
            {{ notification.title }}
          </strong>
          <time class="text-xs/5 font-normal text-neutral-low-dark">{{
            notification?.last_send || notification?.updated_at
              | date: 'mediumDate'
          }}</time>
        </header>
        <div
          class="!mb-1 max-h-[15rem] overflow-auto [&_*]:!mb-0 [&_*]:!bg-transparent [&_*]:text-sm/normal"
          [innerHTML]="notification.description"
        ></div>
      </article>
    }
  }
</div>
