<div class="relative">
  <div id="notification-block__buttonWrapper">
    <span
      class="!invisible absolute left-1/2 top-[0.3125rem] z-[1] h-[0.875rem] w-[0.875rem] scale-[0.25] rounded-full border-2 border-solid border-neutral-hight-pure bg-feedback-warning-pure [&.is-active]:!visible [&.is-active]:!scale-100 [&.is-active]:!transition [&.is-active]:!duration-300 [&.is-active]:!ease-in-out"
      [ngClass]="{
        'is-active': hasNewNotification
      }"
    ></span>
    <commons-icon-button
      svgPath="assets/icons/bell.svg"
      [small]="true"
      [disabled]="(lastNotifications$ | async) === null"
      (buttonClick)="handleToggleNotifications()"
    ></commons-icon-button>
  </div>
  @if (lastNotifications$ | async; as notifications) {
    <div
      class="pointer-events-none !invisible absolute left-[125%] top-12 z-20 h-0 w-[80vw] max-w-[26.25rem] origin-top translate-x-[calc(-100%+29px)] scale-y-0 rounded-lg rounded-tr-none bg-neutral-hight-pure opacity-0 shadow-[0px_4px_4px_rgba(0,0,0,0.25)] transition duration-300 ease-out before:absolute before:-top-2 before:right-[2.625rem] before:size-0 before:border-8 before:border-t-0 before:border-transparent before:!border-b-neutral-hight-light [&.is-active]:!pointer-events-auto [&.is-active]:!visible [&.is-active]:!h-[unset] [&.is-active]:!translate-x-[calc(-100%+29px)] [&.is-active]:!scale-y-100 [&.is-active]:!opacity-100 [&.is-active]:!transition [&.is-active]:!duration-300 [&.is-active]:ease-in"
      [ngClass]="{
        'is-active': expandNotificationDropdown
      }"
    >
      <div>
        <header
          class="border-b-[1px] border-b-neutral-hight-dark !px-6 !pb-4 !pt-6"
        >
          <h4 class="text-xl/5 font-medium text-neutral-low-dark">
            Notificações
          </h4>
        </header>
        <main class="max-h-[62.5vh] overflow-y-auto !px-2 !pb-0 !pt-3">
          @if (selectedNotification) {
            <liv-notification-item
              [notification]="selectedNotification"
              (seeNotification)="toggleSeeNotification($event)"
            ></liv-notification-item>
          } @else {
            @if (notifications?.length > 0) {
              <liv-notifications-list
                [notifications]="notifications"
                (seeNotification)="toggleSeeNotification($event)"
                (allNotificationsSeen)="handleAllNotificationSeen()"
              ></liv-notifications-list>
            } @else {
              <div class="!mt-2">
                <h4 class="!mb-0 leading-7">Ops! 🙁</h4>
                <p>Não há notificações para este ano no momento.</p>
              </div>
            }
          }
        </main>
      </div>
    </div>
  }
</div>

<div
  class="overlay-panel"
  overlay
  (onClickOuside)="onClickOuside()"
  [activeOverlay]="expandNotificationDropdown"
></div>
