<div>
  <ul
    class="flex scale-x-100 transform flex-col !gap-1 transition-all delay-150 duration-150 ease-linear"
  >
    @for (notification of notifications; track notification.id) {
      <li>
        <liv-notification-item
          [notification]="notification"
          [preview]="true"
          [unread]="!notification.read"
          (seeNotification)="handleSeeNotification($event)"
          (allNotificationsSeen)="emitAllNotificationsSeen()"
        ></liv-notification-item>
      </li>
    }
  </ul>
</div>
<div class="sticky bottom-0 bg-neutral-hight-light !px-0 !pb-2 !pt-4">
  <footer class="!pl-4">
    <button
      class="flex items-center text-sm/6 font-semibold text-brand-primary-pure transition-all duration-300 ease-linear enabled:hover:text-brand-primary-pure md:text-base/6"
      (click)="handleSeeAllNotifications()"
      [disabled]="loadingAllNotifications"
    >
      Ver todos
      @if (loadingAllNotifications) {
        <commons-loader class="!ml-2" size="sm"></commons-loader>
      }
    </button>
  </footer>
</div>
