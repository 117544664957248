<article
  class="relative !pr-4 !pt-4 pb-0 pl-[1.125rem]"
  [ngClass]="{
    'border-b !border-b-neutral-hight-dark': preview,
    '!rounded-md !bg-brand-primary-lighter !text-brand-primary-pure': unread
  }"
>
  <header class="flex items-baseline justify-between">
    <span
      class="!visible absolute left-0 top-[1.375rem] z-10 size-2.5 scale-100 transform rounded-full border-0 bg-feedback-warning-pure transition duration-300 ease-in-out"
      [ngClass]="{
        '!bg-feedback-success-pure': !unread
      }"
    ></span>
    <span
      class="line-clamp-2 max-w-[60%] text-lg/6 font-medium text-neutral-low-dark"
      [ngClass]="{
        '!text-brand-primary-pure': unread
      }"
    >
      {{ notification?.title }}
    </span>
    @if (isNewNotification) {
      <span class="text-xs/5 font-normal text-neutral-low-dark">Novo</span>
    } @else {
      <time class="text-xs/5 font-normal text-neutral-low-dark">{{
        notification?.last_send || notification?.created_at | date: 'mediumDate'
      }}</time>
    }
  </header>
  <main>
    <div
      class="mb-1 max-h-60 !overflow-auto [&_*]:m-0 [&_*]:!bg-transparent [&_*]:text-sm"
      [ngClass]="{ '!line-clamp-3 !max-w-[95%] !overflow-hidden': preview }"
      [innerHTML]="notification?.description | safeTransform: 'HTML'"
    ></div>
    @if (preview) {
      <button
        class="!mb-3 !mt-1 text-sm/6 font-medium text-brand-primary-pure transition duration-300 ease-in-out hover:text-brand-primary-pure md:text-base/6"
        (click)="handleSeeNotification(notification)"
      >
        Ver mais
      </button>
    } @else {
      <button
        class="!mb-3 !mt-1 text-sm/6 font-medium text-brand-primary-pure transition duration-300 ease-in-out hover:text-brand-primary-pure md:text-base/6"
        (click)="handleSeeNotification(null)"
      >
        Voltar
      </button>
    }
  </main>
</article>
